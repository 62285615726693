<template>
  <div class="flex flex-col font-Inter mx-8">
    <div class="flex flex-col bg-white shadow font-Inter mt-10 p-4 py-5">
      <div class="flex flex-row mb-4 items-center justify-end">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="handleNewEmployee()"
          >Créer un agent</el-button
        >
      </div>
      <div class="flex flex-col">
        <el-table :data="items" v-loading="listLoading">
          <el-table-column prop="number" label="Numéro" width="140">
          </el-table-column>

          <el-table-column prop="type_value" label="Type"> </el-table-column>

          <el-table-column prop="full_name" label="Nom complet">
          </el-table-column>

          <el-table-column prop="phone" label="Téléphone"> </el-table-column>
          <el-table-column prop="email" label="Email"> </el-table-column>

          <el-table-column align="right">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item mr-2"
                effect="dark"
                content="Modifier l'inventaire "
                placement="bottom-end"
              >
                <el-button
                  type="info"
                  circle
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleUpdate(row)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="listQuery.total > 0"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="fetchData()"
        />
      </div>
    </div>
    <el-dialog
      top="40px"
      width="35%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
    >
      <div slot="title">
        <h2 class="text-2xl" v-if="dialogStatus === 'create'">Nouveau agent</h2>
        <h2 class="text-2xl" v-if="dialogStatus === 'update'">
          Modification l'agent
        </h2>
      </div>
      <el-form
        class="form"
        label-position="top"
        :rules="rules"
        ref="employeeForm"
        :model="employee"
      >
        <el-form-item label="Type d'agent " prop="type">
          <el-select
            class="mr-2"
            v-model="employee.type"
            placeholder="Veuillez selectionner"
          >
            <el-option
              v-for="item in employeeTypes"
              :key="item.name"
              :label="item.libelle"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="full_name">
          <span slot="label">Nom complet </span>
          <el-input class="w-full" type="text" v-model="employee.full_name" />
        </el-form-item>
        <el-form-item label="Téléphone">
          <el-input class="w-full" type="text" v-model="employee.phone" />
        </el-form-item>
        <el-form-item label="Email">
          <el-input class="w-full" type="text" v-model="employee.email" />
        </el-form-item>

        <div class="flex flex-row justify-end">
          <el-button @click="handleCloseDialog" class="mt-5">Annuler</el-button>
          <el-button
            class="mt-5"
            type="primary"
            :loading="loading"
            :disabled="loading"
            @click="
              dialogStatus === 'create'
                ? createData('employeeForm')
                : updateData('employeeForm')
            "
          >
            Enregistrer
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  createEmployee,
  updateEmployee,
  getEmployees,
  getEmployeeType,
} from "@/api/employee";
import Pagination from "@/components/Pagination";
export default {
  name: "Employee",
  components: { Pagination },

  data() {
    return {
      loading: false,
      listLoading: false,
      activeName: "ALL",
      employee: {
        id: undefined,
        number: "",
        full_name: "",
        phone: "",
        email: "",
        type: "",
      },
      items: [],
      employeeTypes: [],
      dialogStatus: "create",
      dialogFormVisible: false,

      rules: {
        full_name: [
          {
            required: true,
            message: "Nom est obligatoire",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "Type est obligatoire",
            trigger: "blur",
          },
        ],
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        seach: undefined,
      },
    };
  },
  mounted() {
    this.fetchData();
    this.fetchTypes();
  },
  methods: {
    handleNewEmployee() {
      this.dialogStatus = "create";
      this.$nextTick(() => {
        this.$refs["employeeForm"].clearValidate();
      });
      this.dialogFormVisible = true;
      this.resetData();
    },
    createData(empolyeeForm) {
      this.loading = true;
      this.$refs[empolyeeForm].validate((valid) => {
        if (valid) {
          createEmployee(this.employee)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.dialogFormVisible = false;
                this.fetchData();
              }, 1.5 * 1000);
            })
            .catch((error) => {
              this.loading = false;
              this.$message.error(error.data.message);
            });
        }
      });
    },
    resetData() {
      this.employee = {
        id: undefined,
        number: "",
        full_name: "",
        phone: "",
        email: "",
        type: "",
      };
    },

    handleCloseDialog() {
      this.dialogFormVisible = false;
      this.resetData();
    },
    handleUpdate(row) {
      this.resetData();

      this.employee = Object.assign({}, row); // copy obj

      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["employeeForm"].clearValidate();
      });
    },
    async fetchData() {
      this.listLoading = true;
      await getEmployees(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;

            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          this.listLoading = false;
          this.$message.error(error.data.message);
        });
    },
    updateData() {
      this.loading = true;
      updateEmployee(this.employee, this.employee.number)
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.dialogFormVisible = false;

            this.fetchData();
          }, 1.5 * 1000);
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.data.message);
        });
    },
    async fetchTypes() {
      getEmployeeType()
        .then((res) => {
          this.employeeTypes = Object.assign({}, res.data);
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
