<template>
  <div class="flex flex-col font-Inter mx-8">
    <div class="flex flex-col bg-white shadow font-Inter lg:w-1/2 mt-10 p-4 py-5">
      <div class="flex flex-row mb-4 items-center justify-start">
        <h1 class="text-xl font-medium">Préferences</h1>
      
      </div>
      <div class="flex flex-col">
       <div v-for="(item, index) in preferences" :key="index" class="flex justify-between">
        <div>{{item.description}}</div>
         <div>
         <el-switch
             v-model="item.currentVal"
             @change="handleChange(item)"
         />
         </div>
       </div>
      </div>
    </div>
 
  </div>
</template>

<script>

import {fetchPreferences, updateUser} from "@/api/preference";

export default {
  name: "PREFERENCE",
  components: {},

  data() {
    return {
      loading: false,
      listLoading: false,
      preferences: [],
  
    };
  },
  mounted() {
  this.fetchPreferences()
  },
  methods: {
    async fetchPreferences(){
      await fetchPreferences()
          .then(res => {
            this.preferences = res.data.map(el => {
              if(el.type === 'Boolean') {
                el.currentVal = el.currentVal === 'false' ? false :true ;
              }
              return el;
            });
            
          })
          .catch(err => { console.log(err) })
    },
    handleChange(item) {

      updateUser(item.id,item).then(res => {
        console.log(res)
      })
      console.log(item);
    }
  },
};
</script>

<style lang="scss" scoped></style>
