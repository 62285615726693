<template>
  <div class="flex flex-col bg-white shadow p-4 mx-8 mt-6">
    <el-table :data="numeros" style="width: 100%">
      <el-table-column prop="type_doc" label="TYPE DE DOCUMENT" min-width="180">
      </el-table-column>
      <el-table-column prop="name" label="PREFIX" width="120">
        <template slot-scope="{ row }">
          <el-input @blur="handleEdit(row.id)" v-model="row.prefix"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="FORMAT DATE">
        <template slot-scope="{ row }">
          <el-select
            @change="handleEdit(row.id)"
            v-model="row.format_date"
            placeholder="Select"
          >
            <el-option
              v-for="item in formateTypes"
              :key="item.name"
              :label="item.label"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="COMPTEUR">
        <template slot-scope="{ row }">
          <el-input-number
            @blur="handleEdit(row.id)"
            v-model="row.current_number"
            :controls="false"
          ></el-input-number>
        </template>
      </el-table-column>
      <!-- <el-table-column width="120" align="right" label="">
        <template slot-scope="{ row }">
          <el-button
            circle
            size="small"
            type="info"
            @click="handleEdit(row.id)"
            class="el-icon-edit"
          ></el-button>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import { fetchNumeroDoc, updateNumeroDoc } from "@/api/settings";
export default {
  name: "Numerotation",
  data() {
    return {
      numeros: [],
      formateTypes: [
        { name: "ddMMyyyy", label: "jjmmaaaa" },
        { name: "MMyyyy", label: "mmaaaa" },
        { name: "MMyy", label: "mmaa" },
        { name: "ddMM", label: "jjmm" },
        { name: null, label: "Aucun" },
      ],
      numero: {
        numero_id: "",
        prefix: "",
        format_date: "",
        current_number: "",
      },
    };
  },
  mounted() {
    this.FETCH_NUMERO_DOC();
  },
  methods: {
    async FETCH_NUMERO_DOC() {
      await fetchNumeroDoc()
        .then((res) => {
          this.numeros = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleEdit(id) {
      const numero = this.numeros.find((el) => el.id === id);
      if (numero.format_date == "Aucun") numero.format_date = null;
      updateNumeroDoc(numero).then(() => {
        console.log("modifier");
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
