<template>
  <div class="flex flex-col items-center justify-center mx-8 mt-6">
    <div class="bg-white shadow-sm border w-full mb-4 px-8 py-5 mt-3">
      <el-form
        :model="settingsInvoice"
        ref="invoiceForm"
        :label-position="labelPosition"
      >
        <el-form-item label="Condition générale des ventes">
          <el-input
            v-model="settingsInvoice.generale_condition"
            class="mt-3 mb-3"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 4 }"
          ></el-input>
        </el-form-item>

        <el-form-item label="Message de remerciement">
          <el-input
            v-model="settingsInvoice.message_thanks"
            class="mt-3 mb-3"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 4 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="Compte reception des paiements">
          <el-select
            class="w-full"
            v-model="settingsInvoice.payment_account"
            placeholder="Choisir le compte"
          >
            <el-option
              v-for="item in accounts"
              :key="item.account_id"
              :label="`${item.account_name}`"
              :value="item.numero"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" class="float-right">Enregistrer</el-button>
      </el-form>
    </div>
    <div class="bg-white shadow-sm border mb-4 px-8 py-5">
      <div class="float-right mb-2">
        <el-button type="primary" icon="el-icon-plus" @click="handleAddDueDay"
          >Ajouter</el-button
        >
      </div>

      <el-table :data="modaliteData" style="width: 100%">
        <el-table-column prop="terms" label="Description" min-width="180">
        </el-table-column>
        <el-table-column prop="days" align="right" label="Valeur" width="180">
        </el-table-column>
        <el-table-column prop="days" align="right" width="90">
          <template slot-scope="{ row }">
            <el-button
              @click="deleteDueDay(row.id)"
              type="danger"
              size="mini"
              icon="el-icon-delete"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      title="Ajouter une condition  de paiement"
      :visible.sync="dialogDueDay"
      width="30%"
      top="9vh"
      :close-on-click-modal="false"
    >
      <el-form
        :model="dueDays"
        :rules="rules"
        ref="dueDaysForm"
        :label-position="labelPosition"
      >
        <el-form-item prop="days" label="Entrer la valeur en jour ">
          <el-input-number cl :controls="false" v-model="dueDays.days">
          </el-input-number>
        </el-form-item>
        <el-form-item prop="terms" label="Description ">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 4 }"
            v-model="dueDays.terms"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogDueDay = false">Annuler</el-button>
        <el-button
          type="primary"
          :loading="loadingButton"
          @click="addDueDay('dueDaysForm')"
          >Enregistrer</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchDueDays,
  createDueDays,
  deleteDueDays,
  getSettingSales,
} from "@/api/settings";
import { fetchAccounts } from "@/api/account";
export default {
  name: "VENTE",
  data() {
    return {
      dialogDueDay: false,
      labelPosition: "top",
      loadingButton: false,
      dueDays: {
        days: 0,
        terms: "",
      },
      settingsInvoice: {
        generale_condition: "",
        message_thanks: "",
        numero: "",
      },
      modaliteData: [],
      accounts: [],
      rules: {
        terms: [
          {
            required: true,
            message: "Le titre est obligatoire",
            trigger: "blur",
          },
        ],
        days: [
          {
            required: true,
            message: "La valeur est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.fetchData();
    this.fetchSettingsSales();
    this.getAccounts();
  },
  methods: {
    handleAddDueDay() {
      this.dialogDueDay = true;
    },
    async fetchData() {
      await fetchDueDays().then((res) => {
        this.modaliteData = res.data;
      });
    },
    addDueDay(dueDaysForm) {
      this.$refs[dueDaysForm].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          createDueDays(this.dueDays)
            .then((res) => {
              setTimeout(() => {
                this.modaliteData.push(res.data);
                this.dialogDueDay = false;
                this.loadingButton = false;
              }, 1.5 * 1000);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    deleteDueDay(id) {
      deleteDueDays(id).then(() => {
        const index = this.modaliteData.find((el) => el.id === id);
        this.modaliteData.splice(index, 1);
      });
    },
    async fetchSettingsSales() {
      await getSettingSales().then((res) => {
        this.settingsInvoice = { ...res.data };
        this.settingsInvoice.numero = res.data.payment_account;
      });
    },
    async getAccounts() {
      await fetchAccounts()
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
