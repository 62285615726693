import request from "@/utils/request";

export function updateOrganization(data, tenant) {
  return request({
    url: `/organization/${tenant}`,
    method: "put",
    data,
  });
}

export function addOrganization(data) {
  return request({
    url: "/organization",
    method: "post",
    data,
  });
}

export function fetchOrganization(query) {
  return request({
    url: "/organization/fetch",
    method: "get",
    params: query,
  });
}

export function fetchOrganizationCurrent() {
  return request({
    url: "/organization/tenant-current",
    method: "get",
  });
}


export function changeTenant(identity) {
  return request({
    url: `/organization/tenant-change/${identity}`,
    method: "get",
  });
}