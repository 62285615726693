<template>
  <div class="flex flex-col font-Inter mx-8">
    <div class="flex flex-col bg-white shadow mt-7 font-Inter p-4">
      <div class="flex flex-row mb-4 items-center justify-between">
        <h1 class="text-xl">Gestion des taxes</h1>
        <el-button
          type="primary"
          icon="el-icon-circle-plus"
          @click="handleNewTaxe()"
          >Créer
        </el-button>
      </div>
      <div class="flex flex-col">
        <el-table :data="items" v-loading="listLoading">
          <el-table-column prop="name" label="Nom" width="250">
          </el-table-column>

          <el-table-column prop="" label="Taux" min-width="150">
            <template slot-scope="{ row }">
              <span>{{ row.value }} %</span>
            </template>
          </el-table-column>

          <el-table-column align="right">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item mr-2"
                effect="dark"
                content="Modifier la categorie "
                placement="bottom-end"
              >
                <el-button
                  type="info"
                  circle
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleUpdate(row)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      top="40px"
      width="25%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
    >
      <div slot="title">
        <h2 class="text-2xl" v-if="dialogStatus === 'create'">CRÉER UN TAXE</h2>
        <h2 class="text-2xl" v-if="dialogStatus === 'update'">
          MODIFIER UN TAXE
        </h2>
      </div>
      <el-form class="form" :rules="rules" ref="taxeForm" :model="taxe">
        <el-form-item label="Nom" prop="name">
          <el-input class="w-full" type="text" v-model="taxe.name" />
        </el-form-item>
        <el-form-item label="Taux(%)" prop="value">
          <el-input-number
            controls-position="right"
            class="w-full"
            type="text"
            v-model="taxe.value"
            :min="0"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Annuler</el-button>
        <el-button
          type="primary"
          :loading="loading"
          :disabled="loading"
          @click="
            dialogStatus === 'create'
              ? createData('taxeForm')
              : updateData('taxeForm')
          "
        >
          Enregistrer
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { fetchTaxes, updateTaxes, createTaxes } from "@/api/settings";
import { parseTime } from "@/utils";
export default {
  name: "Taxe",
  components: {},
  filters: {
    filterTime: parseTime,
  },
  data() {
    return {
      loading: false,
      listLoading: false,
      activeName: "ALL",
      taxe: {
        id: undefined,
        name: "",
        value: 0,
      },
      items: [],
      dialogStatus: "create",
      dialogFormVisible: false,

      rules: {
        name: [
          {
            required: true,
            message: "Libelle est obligatoire",
            trigger: "change",
          },
        ],
        value: [
          {
            required: true,
            message: "Taux est est obligatoire",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleNewTaxe() {
      this.$nextTick(() => {
        this.$refs["taxeForm"].clearValidate();
      });
      this.dialogFormVisible = true;
    },
    createData(taxeForm) {
      this.loading = true;
      this.$refs[taxeForm].validate((valid) => {
        if (valid) {
          createTaxes(this.taxe)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.dialogFormVisible = false;
                this.fetchData();
              }, 1.5 * 1000);
            })
            .catch((error) => {
              this.loading = false;
              this.$message.error(error.data.message);
            });
        }
      });
    },
    resetData() {
      this.taxe = {
        id: undefined,
        name: "",
        value: 0,
      };
    },
    handleUpdate(row) {
      this.taxe = Object.assign(
        {},
        {
          id: row.id,
          name: row.name,
          value: row.value,
        }
      ); // copy obj

      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["categoryForm"].clearValidate();
      });
    },
    async fetchData() {
      this.listLoading = true;
      await fetchTaxes()
        .then((res) => {
          setTimeout(() => {
            this.items = res.data;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          this.listLoading = false;
          this.$message.error(error.data.message);
        });
    },
    updateData(taxeForm) {
      this.loading = true;

      this.$refs[taxeForm].validate((valid) => {
        if (valid) {
          updateTaxes(this.taxe, this.taxe.id)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.dialogFormVisible = false;
                this.fetchData();
              }, 1.5 * 1000);
            })
            .catch((error) => {
              this.loading = false;
              this.$message.error(error.data.message);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
