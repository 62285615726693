<template>
  <div class="flex flex-col font-Inter mx-8">
    <div class="flex flex-col bg-white shadow font-Inter mt-10 p-4 py-5">
      <div class="flex flex-row mb-4 items-center justify-end">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="handleNewWarehouse()"
          >Créer un entrepôt</el-button
        >
      </div>
      <div class="flex flex-col">
        <el-table :data="items" v-loading="listLoading">
          <el-table-column prop="code" width="140">
            <template slot-scope="{ row }">
              <el-switch
                v-model="row.hasPrimary"
                @change="activePrimary(row.code)"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column prop="code" label="Code" width="140">
          </el-table-column>

          <el-table-column prop="name" label="Nom"> </el-table-column>

          <el-table-column prop="street" label="Adresse" min-width="150">
          </el-table-column>

          <el-table-column align="right">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item mr-2"
                effect="dark"
                content="Modifier l'inventaire "
                placement="bottom-end"
              >
                <el-button
                  type="info"
                  circle
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleUpdate(row)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      top="40px"
      width="30%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
    >
      <div slot="title">
        <h2 class="text-2xl" v-if="dialogStatus === 'create'">
          Nouveau entrepôt
        </h2>
        <h2 class="text-2xl" v-if="dialogStatus === 'update'">
          Modification entrepôt
        </h2>
      </div>
      <el-form
        class="form"
        :rules="rules"
        ref="warehouseForm"
        :model="warehouse"
      >
        <el-form-item label="Code" prop="code">
          <span slot="label">Code Entrepôt </span>
          <el-input
            class="w-full"
            type="text"
            v-model="warehouse.code"
            :disabled="dialogStatus === 'create' ? false : true"
          />
          <p
            class="text-red-400"
            v-if="dialogStatus === 'create' ? true : false"
          >
            Attention une fois l'entrepôt crée vous pouvez pas modifier le code
          </p>
        </el-form-item>
        <el-form-item label="Nom" prop="name">
          <el-input class="w-full" type="text" v-model="warehouse.name" />
        </el-form-item>
        <el-form-item label="Adresse" prop="street">
          <el-input class="w-full" type="text" v-model="warehouse.street" />
        </el-form-item>

        <div class="flex flex-row justify-end">
          <el-button @click="handleCloseDialog" class="mt-5">Annuler</el-button>
          <el-button
            class="mt-5"
            type="primary"
            :loading="loading"
            :disabled="loading"
            @click="
              dialogStatus === 'create'
                ? createData('warehouseForm')
                : updateData('warehouseForm')
            "
          >
            Enregistrer
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  createWarehouse,
  updateWarehouse,
  fetchWarehouse,
  activePrimaryWarehouse,
} from "@/api/stock";
import { parseTime } from "@/utils";
export default {
  name: "List-Inventory",
  components: {},
  filters: {
    filterTime: parseTime,
  },
  data() {
    return {
      loading: false,
      listLoading: false,
      activeName: "ALL",

      warehouse: {
        id: undefined,
        code: "",
        name: "",
        street: "",
      },
      items: [],
      dialogStatus: "create",
      dialogFormVisible: false,

      rules: {
        name: [
          {
            required: true,
            message: "Libelle est obligatoire",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "Code entrepoôt est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleNewWarehouse() {
      this.dialogStatus = "create";
      this.$nextTick(() => {
        this.$refs["warehouseForm"].clearValidate();
      });
      this.dialogFormVisible = true;
      this.resetData();
    },
    createData(warehouseForm) {
      this.loading = true;
      this.$refs[warehouseForm].validate((valid) => {
        if (valid) {
          createWarehouse(this.warehouse)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.dialogFormVisible = false;
                this.fetchData();
              }, 1.5 * 1000);
            })
            .catch((error) => {
              this.loading = false;
              this.$message.error(error.data.message);
            });
        }
      });
    },
    resetData() {
      this.warehouse = {
        id: undefined,
        code: "",
        name: "",
        street: "",
      };
    },

    handleCloseDialog() {
      this.dialogFormVisible = false;
      this.resetData();
    },
    handleUpdate(row) {
      this.resetData();

      this.warehouse = Object.assign(
        {},
        {
          id: row.id,
          street: row.street,
          name: row.name,
          code: row.code,
          primary: row.hasPrimary,
        }
      ); // copy obj

      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["warehouseForm"].clearValidate();
      });
    },
    async fetchData() {
      this.listLoading = true;
      await fetchWarehouse()
        .then((res) => {
          setTimeout(() => {
            this.items = res.data;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          this.listLoading = false;
          this.$message.error(error.data.message);
        });
    },
    updateData() {
      this.loading = true;
      updateWarehouse(this.warehouse, this.warehouse.code)
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.dialogFormVisible = false;

            this.fetchData();
          }, 1.5 * 1000);
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.data.message);
        });
    },
    async activePrimary(id) {
      activePrimaryWarehouse(id)
        .then(() => {
          this.$message({
            type: "success",
            message: "Entrepôt principale actif",
          });
          this.fetchData();
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
