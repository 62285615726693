<template>
  <div id="config">
    <ul class="menu">
      <li>
        <a
          :class="{ active: activeName === 'STORE' }"
          @click="handleMenu('STORE')"
          >Information</a
        >
      </li>
      <li>
        <a
          :class="{ active: activeName === 'EMPLOYEE' }"
          @click="handleMenu('EMPLOYEE')"
          >Agents</a
        >
      </li>
      <li>
        <a
          :class="{ active: activeName === 'SALE' }"
          @click="handleMenu('SALE')"
          >Facturation</a
        >
      </li>
      <li>
        <a
          :class="{ active: activeName === 'NUMBER' }"
          @click="handleMenu('NUMBER')"
          >Numérotation</a
        >
      </li>
      <li>
        <a
          :class="{ active: activeName === 'WAREHOUSE' }"
          @click="handleMenu('WAREHOUSE')"
          >Entrepôts
        </a>
      </li>
      <li>
        <a
          :class="{ active: activeName === 'CATEGORY' }"
          @click="handleMenu('CATEGORY')"
          >Catégories
        </a>
      </li>
      <li>
        <a
          :class="{ active: activeName === 'TAXE' }"
          @click="handleMenu('TAXE')"
          >Taxes
        </a>
      </li>

      <li>
        <a
          :class="{ active: activeName === 'PREFERENCE' }"
          @click="handleMenu('PREFERENCE')"
          >Préferences</a
        >
      </li>

        <li>
            <a
                    :class="{ active: activeName === 'USER' }"
                    @click="handleMenu('USER')"
            >Utilisateurs</a
            >
        </li>

      <!--  <li>
        <a
          :class="{ active: activeName === 'CURRENCY' }"
          @click="handleMenu('CURRENCY')"
          >Devise</a
        >
      </li> -->
    </ul>
    <div class="content">
      <Organization v-if="activeName === 'STORE'" />
      <Numerotation v-if="activeName === 'NUMBER'" />
      <Sales v-if="activeName === 'SALE'" />
      <Warehouse v-if="activeName === 'WAREHOUSE'" />
      <Category v-if="activeName === 'CATEGORY'" />
      <Taxe v-if="activeName === 'TAXE'" />
      <Employee v-if="activeName === 'EMPLOYEE'" />
        <User v-if ="activeName ==='USER'" />
        <PREFERENCE v-if ="activeName ==='PREFERENCE'" />
    </div>
  </div>
</template>

<script>
import Organization from "./Organization";
import Numerotation from "./number";
import Sales from "./sale";
import Warehouse from "./Warehouse";
import Category from "./categories";
import Taxe from "./taxes";
import Employee from "./Employee";
import User from "./User";
import PREFERENCE from "./Preference";

export default {
  name: "SETTINGS",
  components: {
    PREFERENCE,
    Organization,
    Numerotation,
    Sales,
    Warehouse,
    Category,
    Taxe,
    Employee,
      User
  },
  data() {
    return {
      activeName: "STORE",
    };
  },
  watch: {
    activeName(val) {
      this.$router.push(`${this.$route.path}?menu=${val}`);
    },
  },
  created() {
    const menu = this.$route.query.menu;

    if (menu) {
      this.activeName = menu;
    }
  },
  methods: {
    handleMenu(val) {
      this.activeName = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
#config {
  position: relative;
}
#config .menu {
  padding-top: 30px;
  padding-left: 12px;
  border-top: 1px solid #ddd;
  transition: width 0.28s;
  width: 200px !important;
  background-color: white;
  // border-right: 1px solid #ddd;
  height: 100%;
  position: fixed;
  overflow: hidden;
  box-shadow: 1px 0px 4px rgba(0, 21, 41, 0.08);
  a {
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    font-weight: 400;
  }
  a:hover {
    transition: color 0.28s;
    font-weight: 600;
    color: $subMenuBg;
  }
  .active {
    border-right: 3px solid $primary;
    color: $primary;
    font-weight: 500;
  }
}
#config .content {
  position: relative;
  margin-left: 200px;
  padding: 8px 12px;
}
</style>
