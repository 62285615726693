<template>
  <div class="flex flex-col font-Inter mx-8 mt-6">
    <div class="bg-white shadow mb-4 p-8">
      <update-organization />
    </div>
    <!-- <div class="bg-white shadow-sm border w-full mb-4 px-8 py-8">
        <div class="flex flex-row justify-between pb-4">
          <h5 class="text-3xl font-semibold mb-3">
            {{ organizationCurrent.name }}
          </h5>
        </div>
        <div class="flex flex-row">
          <div class="w-24 h-24 mr-4 flex flex-col">
            <img src="@/assets/bmw.jpg" alt="" />
            <button class="mt-3 text-blue-400">Changer</button>
          </div>

          <div class="flex flex-col w-1/2">
            <div class="flex flex-row my-1">
              <div class="text-gray-500 w-1/3">Identifiant :</div>
              <div>{{ organizationCurrent.identity }}</div>
            </div>
            <div class="flex flex-row my-1">
              <div class="text-gray-500 w-1/3">Pays :</div>
              <div>{{ organizationCurrent.country }}</div>
            </div>
            <div class="flex flex-row my-1">
              <div class="text-gray-500 w-1/3">Devise :</div>
              <div>
                {{ organizationCurrent.currency }} -
                {{ organizationCurrent.currencyCodeAlpha }}
              </div>
            </div>
            <div class="flex flex-row my-1">
              <div class="text-gray-500 w-1/3">Type :</div>
              <div>{{ organizationCurrent.type }}</div>
            </div>
          </div>
          <div class="flex flex-col w-1/2">
            <div class="flex flex-row my-1">
              <div class="text-gray-500 w-1/3">Email :</div>
              <div>{{ organizationCurrent.email }}</div>
            </div>
            <div class="flex flex-row my-1">
              <div class="text-gray-500 w-1/3">Téléphone :</div>
              <div>{{ organizationCurrent.phone }}</div>
            </div>
            <div class="flex flex-row my-1">
              <div class="text-gray-500 w-1/3">Adresse :</div>
              <div>
                {{ organizationCurrent.street }} -
                {{ organizationCurrent.city }}
              </div>
            </div>
            <div class="flex flex-row my-1">
              <div class="text-gray-500 w-1/3">Role :</div>
              <div>Administrateur</div>
            </div>
          </div>
        </div>
      </div> -->
    <div class="bg-white shadow flex flex-col px-8 py-8">
      <Organization />
    </div>
  </div>
</template>

<script>
/*import Profile from "./Profile";
import Preference from "./Preference";
import TemplateDoc from "./TemplateDoc";
import User from "./User"; */
import Organization from "./liste.vue";
import UpdateOrganization from "./update.vue";
import { fetchOrganizationCurrent } from "@/api/organization";
//import Item from "./Item.vue";
export default {
  name: "Setting",
  components: {
    /*Profile, Preference, TemplateDoc, User,*/ Organization,
    UpdateOrganization,
  },
  data() {
    return {
      step: "Organisations",
      organizationCurrent: null,
      menuData: [
        {
          title: "Profile",
          menuAcive: false,
        },
        {
          title: "Organisations",
          menuAcive: false,
        },
        {
          title: "Utilisateurs",
          menuAcive: false,
        },
        {
          title: "Préfrences",
          menuAcive: false,
        },
        {
          title: "Templates",
          menuAcive: false,
        },
      ],
    };
  },
  mounted() {
    this.getOrganization();
  },
  methods: {
    changeStep(step) {
      console.log(step);
      switch (step) {
        case "Profile":
          this.step = "Profile";
          break;

        case "Organisations":
          this.step = "Organisations";
          break;

        case "Templates":
          this.step = "Templates";
          break;

        case "Utilisateurs":
          this.step = "Utilisateurs";
          break;

        case "Préfrences":
          this.step = "Préfrences";
          break;
      }
    },
    getOrganization() {
      fetchOrganizationCurrent()
        .then((res) => {
          this.organizationCurrent = { ...res.data };
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
}
</style>
