<template>
  <div class="flex flex-col font-Inter mx-8">
    <div class="flex flex-col bg-white shadow mt-7 font-Inter p-4">
      <div class="flex flex-row mb-4 items-center justify-between">
        <h1 class="text-xl">Categories d'article</h1>
        <el-button
          type="primary"
          icon="el-icon-circle-plus"
          @click="handleNewCategory()"
          >Créer
        </el-button>
      </div>
      <div class="flex flex-col">
        <el-table :data="items" v-loading="listLoading">
          <el-table-column prop="name" label="Nom" width="250">
          </el-table-column>

          <el-table-column prop="street" label="Description" min-width="150">
          </el-table-column>

          <el-table-column align="right">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item mr-2"
                effect="dark"
                content="Modifier la categorie "
                placement="bottom-end"
              >
                <el-button
                  type="info"
                  circle
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleUpdate(row)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      top="40px"
      width="25%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
    >
      <div slot="title">
        <h2 class="text-2xl" v-if="dialogStatus === 'create'">
          CRÉER UNE CATEGORIE
        </h2>
        <h2 class="text-2xl" v-if="dialogStatus === 'update'">
          MODIFIER UNE CATEGORIE
        </h2>
      </div>
      <el-form class="form" :rules="rules" ref="categoryForm" :model="category">
        <el-form-item label="Nom" prop="name">
          <el-input class="w-full" type="text" v-model="category.name" />
        </el-form-item>
        <el-form-item label="Description" prop="street">
          <el-input class="w-full" type="text" v-model="category.describe" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Annuler</el-button>
        <el-button
          type="primary"
          :loading="loading"
          :disabled="loading"
          @click="
            dialogStatus === 'create'
              ? createData('categoryForm')
              : updateData('categoryForm')
          "
        >
          Enregistrer
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchCategories,
  createCategory,
  updateCategory,
} from "@/api/settings";
import { parseTime } from "@/utils";
export default {
  name: "Category-Article",
  components: {},
  filters: {
    filterTime: parseTime,
  },
  data() {
    return {
      loading: false,
      listLoading: false,
      activeName: "ALL",
      category: {
        id: undefined,
        name: "",
        describe: "",
      },
      items: [],
      dialogStatus: "create",
      dialogFormVisible: false,

      rules: {
        name: [
          {
            required: true,
            message: "Libelle est obligatoire",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleNewCategory() {
      this.$nextTick(() => {
        this.$refs["categoryForm"].clearValidate();
      });
      this.dialogFormVisible = true;
    },
    createData(categoryForm) {
      this.loading = true;
      this.$refs[categoryForm].validate((valid) => {
        if (valid) {
          createCategory(this.category)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.dialogFormVisible = false;
                this.fetchData();
              }, 1.5 * 1000);
            })
            .catch((error) => {
              this.loading = false;
              this.$message.error(error.data.message);
            });
        }
      });
    },
    resetData() {
      this.category = {
        id: undefined,
        name: "",
        describe: "",
      };
    },
    handleUpdate(row) {
      this.category = Object.assign(
        {},
        {
          id: row.id,
          name: row.name,
          describe: row.describe,
        }
      ); // copy obj

      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["categoryForm"].clearValidate();
      });
    },
    async fetchData() {
      this.listLoading = true;
      await fetchCategories()
        .then((res) => {
          setTimeout(() => {
            this.items = res.data;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          this.listLoading = false;
          this.$message.error(error.data.message);
        });
    },
    updateData() {
      this.loading = true;
      updateCategory(this.warehouse, this.warehouse.id)
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.dialogFormVisible = false;
            this.fetchData();
          }, 1.5 * 1000);
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
