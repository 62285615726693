<template>
  <div class="flex flex-col 4" v-loading="listLoading">
    <div class="mb-4 flex justify-between items-center">
      <div class="text-xl">Autres Boutiques</div>
<!--      <el-button type="primary" class="el-icon-plus" @click="handleAdd"-->
<!--        ><span class="font-Inter font-bold ml-2">Créer</span></el-button-->
<!--      >-->
    </div>
    <el-table :data="items" style="width: 100%">
      <el-table-column label="N° TENANT" width="150">
        <template slot-scope="{ row }">
          <span class="text-primary">{{ row.identity }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="Entreprise" min-width="180">
        <template slot-scope="{ row }">
          <div class="flex flex-col text-md">
            <h2 class="font-normal">{{ row.name }}</h2>

            <div class="text-gray-400 text-sm pt-1">
              Téléphone :
              <span>{{ row.phone }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="80" align="center">
        <template slot-scope="{ row }">
          {{ row.active }}
          <el-switch
            v-model="row.current"
            :loading="true"
            @change="changeTenant(row.identity)"
          >
          </el-switch>
        </template>
      </el-table-column>

      <!--     <el-table-column width="80" align="right">
        <template slot-scope="{ row }">
          <el-button
            @click="handleUpdate(row)"
            size="mini"
            type="info"
            icon="el-icon-edit"
            circle
          >
          </el-button>
        </template>
      </el-table-column> -->
    </el-table>

    <div class="flex justify-end">
      <pagination
        v-show="listQuery.total > 0"
        :total="listQuery.total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.size"
        @pagination="getOrgnaization()"
      />
    </div>

    <el-dialog
      top="62px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="showDialog"
    >
      <div slot="title">
        <h2 class="text-2xl" v-if="dialogStatus === 'add'">
          Ajouter une boutique
        </h2>
        <h2 class="text-2xl" v-if="dialogStatus === 'update'">
          Modifier une boutique
        </h2>
      </div>
      <el-form
        class="form"
        v-loading="loadingAdd"
        :rules="rules"
        ref="organizationFrom"
        :model="organization"
      >
        <el-form-item label="Type" prop="type_id">
          <el-select
            class="w-full"
            v-model="organization.type_id"
            placeholder="Veuillez selectionner"
          >
            <el-option
              v-for="item in types"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Dénomination de la boutique" prop="name">
          <el-input class="w-full" type="text" v-model="organization.name" />
        </el-form-item>
        <el-form-item label="Téléphone" prop="phone">
          <el-input class="w-full" type="phone" v-model="organization.phone" />
        </el-form-item>
        <div class="flex flex-row">
          <el-form-item label="Pays" class="w-1/2 mr-1" prop="country_id">
            <el-select
              class="w-full"
              v-model="organization.country_id"
              placeholder="Veuillez selectionner"
            >
              <el-option
                v-for="item in countries"
                :key="item.id"
                :label="`${item.name}`"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Devise" class="w-1/2" prop="currency_id">
            <el-select
              class="w-full"
              v-model="organization.currency_id"
              placeholder="Veuillez selectionner"
            >
              <el-option
                v-for="item in currencies"
                :key="item.id"
                :label="`${item.name} - ${item.codeAlpha}`"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flex flex-row">
          <el-form-item label="Email" class="w-1/2 mr-1" prop="email">
            <el-input
              class="w-full"
              type="email"
              v-model="organization.email"
            />
          </el-form-item>

          <el-form-item class="w-1/2" label="Site web" prop="website">
            <el-input
              class="w-full"
              type="text"
              v-model="organization.website"
            />
          </el-form-item>
        </div>
        <el-form-item label="Adresse" prop="street">
          <el-input class="w-full" type="phone" v-model="organization.street" />
        </el-form-item>

        <div class="flex flex-row">
          <el-button
            class="mt-5"
            type="primary"
            :loading="loading"
            :disabled="loading"
            @click="
              dialogStatus === 'add'
                ? handleSaveOrganization('organizationFrom')
                : handleUpdateOrganization('organizationFrom')
            "
          >
            Enregistrer
          </el-button>
          <el-button class="mt-5" @click="handleCancel">Annuler</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchTypesOrganization,
  fetchCountries,
  fetchCurrencies,
} from "@/api/settings";
import {
  addOrganization,
  fetchOrganization,
  changeTenant,
} from "@/api/organization";
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
export default {
  name: "Organization",
  components: { Pagination },
  data() {
    return {
      showDialog: false,
      loading: false,
      listLoading: false,
      title: "Nouvelle boutique ",
      countries: [],
      currencies: [],
      loadingAdd: false,
      types: [],
      items: [],
      dialogStatus: "add",
      listQuery: {
        total: 0,
        page: 1,
        size: 10,
        sort: "asc",
        sortDir: "createdAt",
      },
      organization: {
        name: "",
        country_id: null,
        currency_id: null,
        street: "",
        city: "",
        website: "",
        email: "",
        phone: "",
        type_id: null,
      },
      rules: {
        name: [
          {
            required: true,
            message: "Le nom est obligatoire",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "Le téléphone est obligatoire",
            trigger: "change",
          },
        ],
        country_id: [
          {
            required: true,
            message: "Veuillez selectionner le pays",
            trigger: "change",
          },
        ],
        currency_id: [
          {
            required: true,
            message: "Veuillez selectionner la devise ",
            trigger: "change",
          },
        ],
        type_id: [
          {
            required: true,
            message: "Veuillez selectionner le type ",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.fetchData();
    // this.getOrgnaization();
  },
  methods: {
    handleAdd() {
      this.showDialog = true;
    },
    fetchData() {
      this.listLoading = true;
      this.fetchCurrencies();
      this.fetchTypes();
      this.fetchCountries();
      this.getOrgnaization();
      setTimeout(() => {
        this.listLoading = false;
      }, 1.5 * 1000);
    },

    getOrgnaization() {
      fetchOrganization(this.listQuery)
        .then((res) => {
          this.items = res.data.items;
          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSaveOrganization(organizationFrom) {
      this.$refs[organizationFrom].validate((valid) => {
        this.loading = true;
        if (valid) {
          addOrganization(this.organization)
            .then((res) => {
              this.dialogFormVisible = false;

              setTimeout(() => {
                this.loading = false;

                this.showDialog = false;

                this.resetOrganization();
                this.items.unshift(res.data);
                this.$message({
                  title: "Success",
                  message: "La boutique ajoutée avec succès",
                  type: "success",
                  duration: 2000,
                });
              }, 3000);
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        }
      });
    },
    handleCancel() {},

    resetOrganization() {
      this.organization = {
        name: "",
        country_id: undefined,
        currency_id: undefined,
        street: "",
        city: "",
        website: "",
        email: "",
        phone: "",
        type_id: undefined,
      };
    },
    handleUpdate(row) {
      this.organization = Object.assign({}, row); // copy obj

      this.dialogStatus = "update";
      this.showDialog = true;
      this.$nextTick(() => {
        this.$refs["organizationFrom"].clearValidate();
      });
    },
    async fetchCountries() {
      await fetchCountries()
        .then((res) => {
          this.countries = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchCurrencies() {
      await fetchCurrencies()
        .then((res) => {
          this.currencies = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async handleUpdateOrganization(organizationFrom) {
      this.$refs[organizationFrom].validate((valid) => {
        this.loading = true;
        if (valid) {
          addOrganization(this.organization)
            .then((res) => {
              this.dialogFormVisible = false;

              setTimeout(() => {
                this.loading = false;

                this.showDialog = false;

                this.resetOrganization();
                this.items.unshift(res.data);
                this.$message({
                  title: "Success",
                  message: "La boutique été modifié avec succès",
                  type: "success",
                  duration: 2000,
                });
              }, 3000);
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        }
      });
    },
    async fetchTypes() {
      await fetchTypesOrganization()
        .then((res) => {
          this.types = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async changeTenant(identity) {
      await changeTenant(identity).then(() => {
        setTimeout(() => {
          this.$message({
            title: "Success",
            message: "La boutique a été activé",
            type: "success",
            duration: 2000,
          });
          window.location.reload();
        }, 1.5 * 1000);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
