<template>
  <div>
    <el-form
      class="form"
      ref="organizationFrom"
      :rules="rules"
      :model="organization"
    >
      <div class="flex">
        <el-form-item label="Type d'entreprise " prop="type_id">
          <el-select
            class="mr-2"
            v-model="organization.type_id"
            placeholder="Veuillez selectionner"
          >
            <el-option
              v-for="item in types"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="flex-1"
          label="Dénomination de la boutique"
          prop="name"
        >
          <el-input class="w-full" type="text" v-model="organization.name" />
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item class="mr-3 w-1/3" label="Téléphone" prop="phone">
          <el-input type="phone" v-model="organization.phone" />
        </el-form-item>
        <el-form-item class="mr-3 w-1/3" label="Téléphone Secondaire">
          <el-input type="phone" v-model="organization.phone_secondary" />
        </el-form-item>
        <el-form-item class="w-1/3" label="Téléphone Fixe">
          <el-input type="phone" v-model="organization.phone_fixe" />
        </el-form-item>
      </div>
      <div class="flex flex-row">
        <el-form-item label="Pays" class="w-1/2 mr-1" prop="country_id">
          <el-select :disabled="true"
            class="w-full"
            v-model="organization.country_id"
            placeholder="Veuillez selectionner"
          >
            <el-option
              v-for="item in countries"
              :key="item.id"
              :label="`${item.name}`"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Devise" class="w-1/2" prop="currency_id">
          <el-select
            class="w-full"
            :disabled="true"
            v-model="organization.currency_id"
            placeholder="Veuillez selectionner"
          >
            <el-option
              v-for="item in currencies"
              :key="item.id"
              :label="`${item.name} - ${item.codeAlpha}`"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="flex flex-row">
        <el-form-item label="Email" class="w-1/2 mr-1" prop="email">
          <el-input class="w-full" type="email" v-model="organization.email" />
        </el-form-item>

        <el-form-item class="w-1/2" label="Site web" prop="website">
          <el-input class="w-full" type="text" v-model="organization.website" />
        </el-form-item>
      </div>
      <el-form-item label="Adresse( Ville , commune, lieux)" prop="street">
        <el-input
          :autosize="{ minRows: 6, maxRows: 4 }"
          class="w-full"
          type="textarea"
          v-model="organization.street"
        />
      </el-form-item>
      <el-form-item label="Description des activités">
        <el-input
          :autosize="{ minRows: 6, maxRows: 4 }"
          class="w-full"
          type="textarea"
          v-model="organization.activity"
        />
      </el-form-item>

      <el-form-item label="Immatriculation (RCCM, NEAN, AUTRES)" prop="rccm">
        <el-input
          :autosize="{ minRows: 6, maxRows: 4 }"
          class="w-full"
          type="textarea"
          v-model="organization.rccm"
        />
      </el-form-item>

      <div class="flex flex-row justify-end">
        <el-button
          class="mt-5"
          type="primary"
          :loading="loading"
          :disabled="loading"
          @click="updateData('organizationFrom')"
        >
          Enregistrer
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  fetchTypesOrganization,
  fetchCountries,
  fetchCurrencies,
} from "@/api/settings";
import {
  updateOrganization,
  fetchOrganizationCurrent,
} from "@/api/organization";
export default {
  name: "ORGANIZATION-UPDATE",
  data() {
    return {
      countries: [],
      currencies: [],
      loading: false,
      types: [],
      organization: {
        name: "",
        country_id: null,
        currency_id: null,
        street: "",
        city: "",
        website: "",
        email: "",
        phone: "",
        phone_secondary: "",
        phone_fixe: "",
        activity: "",
        type_id: null,
        identity: "",
        rccm: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Le nom est obligatoire",
            trigger: "change",
          },
        ],
        street: [
          {
            required: true,
            message: "Le nom est obligatoire",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "Le téléphone est obligatoire",
            trigger: "change",
          },
        ],
        country_id: [
          {
            required: true,
            message: "Veuillez selectionner le pays",
            trigger: "change",
          },
        ],
        currency_id: [
          {
            required: true,
            message: "Veuillez selectionner la devise ",
            trigger: "change",
          },
        ],
        type_id: [
          {
            required: true,
            message: "Veuillez selectionner le type ",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.listLoading = true;
      this.fetchCurrencies();
      this.fetchTypes();
      this.fetchCountries();
      this.getOrganization();
      setTimeout(() => {
        this.listLoading = false;
      }, 1.5 * 1000);
    },

    async fetchCountries() {
      await fetchCountries()
        .then((res) => {
          this.countries = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchCurrencies() {
      await fetchCurrencies()
        .then((res) => {
          this.currencies = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchTypes() {
      await fetchTypesOrganization()
        .then((res) => {
          this.types = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateData(organiztionFrom) {
      this.$refs[organiztionFrom].validate((valid) => {
        if (valid) {
          this.loading = true;
          updateOrganization(this.organization, this.organization.identity)
            .then(() => {
              setTimeout(() => {
                this.loading = false;

                this.$message({
                  title: "Success",
                  message: "Modification effectuée",
                  type: "success",
                  duration: 2000,
                });
              }, 1.5 * 1000);
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        }
      });
    },
    getOrganization() {
      fetchOrganizationCurrent()
        .then((res) => {
          this.organization.name = res.data.name;
          this.organization.country_id = res.data.country_id;
          this.organization.currency_id = res.data.currency_id;
          this.organization.street = res.data.street;
          this.organization.city = res.data.city;
          this.organization.website = res.data.website;
          this.organization.email = res.data.email;
          this.organization.phone = res.data.phone;
          this.organization.phone_secondary = res.data.phone_secondary;
          this.organization.phone_fixe = res.data.phone_fixe;
          this.organization.type_id = res.data.type_id;
          this.organization.identity = res.data.identity;
          this.organization.rccm = res.data.rccm;
          this.organization.activity = res.data.activity;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
